import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 50px 0",
	"background": "--color-dark",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Address"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "4998 Hanover Street, New York, 10011"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Reservation"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+1 650-726-5006",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"children": <>
				+1 650-726-5006{"\n\n"}
			</>
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact@migemyi.com",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "#f5eceb",
			"children": "contact@migemyi.com"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"text-align": "center"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Quick links"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "--light",
			"text-align": "center",
			"font": "--lead",
			"padding": "0px 0px 5px 0px",
			"text-decoration-line": "initial",
			"display": "block",
			"children": "Home"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/menu",
			"color": "--light",
			"text-align": "center",
			"font": "--lead",
			"text-decoration-line": "initial",
			"padding": "0px 0px 5px 0px",
			"display": "block",
			"children": "Menu"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "/contact-us",
			"color": "--light",
			"text-align": "center",
			"font": "--lead",
			"text-decoration-line": "initial",
			"padding": "0px 0px 5px 0px",
			"display": "block",
			"children": "Contact us"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"href": "/policy",
			"color": "--light",
			"text-align": "center",
			"font": "--lead",
			"text-decoration-line": "initial",
			"padding": "0px 0px 5px 0px",
			"display": "block",
			"children": "Privacy Policy"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#d7c7c5",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "migemyi.com  © 2023 All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text2")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
				<Link {...override("link4")} />
				<Link {...override("link5")} />
			</Box>
		</Box>
		<Text {...override("text4")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;