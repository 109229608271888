import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "0 0 0 0",
	"md-padding": "36px 0 36px 0",
	"quarkly-title": "Images-19"
};
const overrides = {
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://migemyi.com/img/interior_4.jpg",
			"object-fit": "cover"
		}
	}
};

const Image19 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" width="100%" max-width="100%" />
		<Image {...override("image")} />
		{children}
	</Section>;
};

Object.assign(Image19, { ...Section,
	defaultProps,
	overrides
});
export default Image19;